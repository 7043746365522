import { APP_TITLE } from '@/consts';
import { defineStore } from 'pinia';

import {
  getCssVar,
  colorIsDark,
  lighten,
  setCssVar,
  humpToUnderline,
  hexToRGB,
  mix,
} from '@/utils';

export const useAppStore = defineStore(
  'app.store',
  () => {
    const useApp = reactive({
      title: '', // 标题
      logo: true, // logo
      theme: {},
      mytopbg: '',
      cardIdActive: '',
      passphraseInfo: {},
      location: null, // 用户位置
      popAd: '',
      showPopAd: false,
      userInfo: {},
      ads: [], // 广告
    });

    const setSetting = (setting) => {
      const { name, logo, ui } = setting;
      useApp.title = name;
      useApp.logo = logo;
      useApp.mytopbg = ui.mytopbg;
      setTheme(ui); // 设置主题
      document.title = useApp.title;
    };

    const setAds = (ads) => {
      useApp.ads = ads;
    };

    const setPrimaryTheme = (color) => {
      setTheme({ elColorPrimary: color });
      setPrimaryLight();
    };
    const setTheme = (theme) => {
      useApp.theme = Object.assign(useApp.theme, theme);
      setCssVarTheme();
    };
    const setCssVarTheme = () => {
      for (const key in useApp.theme) {
        if (key !== 'mytopbg') {
          setCssVar(`--app-${humpToUnderline(key)}`, useApp.theme[key]);
        }
      }
      setPrimaryLight();
      setCardBGColor();
    };

    const setCardBGColor = () => {
      const color = getCssVar('--app-highlight-bg-sub-color').value;
      let op = parseInt(getCssVar('--app-card-bg-num').value || 0);
      if (op < 1) {
        op = 1;
      }
      setCssVar(
        `--app-highlight-bg-sub-color-unselected`,
        hexToRGB(color, 0.1 * op)
      );
    };

    const setPrimaryLight = () => {
      // if (useApp.theme.elColorPrimary) {
      //   const elColorPrimary = useApp.theme.elColorPrimary;
      //   const color = useApp.isDark ? '#000000' : '#ffffff';
      //   const lightList = [3, 5, 7, 8, 9];
      //   lightList.forEach((v) => {
      //     setCssVar(
      //       `--el-color-primary-light-${v}`,
      //       mix(color, elColorPrimary, v / 10)
      //     );
      //   });
      //   setCssVar(`--el-color-primary-dark-2`, mix(color, elColorPrimary, 0.2));
      // }
    };

    const setUserLocation = (location) => {
      useApp.location = location;
    };

    const setPassphraseInfo = (passphraseInfo) => {
      useApp.passphraseInfo = passphraseInfo;
    };

    const setCardIdActive = (cardId) => {
      useApp.cardIdActive = cardId;
    };
    const setPopAd = (url) => {
      if (useApp.popAd != url) {
        useApp.popAd = url;
        setTimeout(() => {
          useApp.showPopAd = true;
        }, 500);
      }
    };

    const setUserInfo = (userInfo) => {
      useApp.userInfo = userInfo;
    };

    return {
      useApp,
      setTheme,
      setPrimaryTheme,
      setCssVarTheme,
      setUserLocation,
      setSetting, // 设置设置
      setPassphraseInfo,
      setCardIdActive,
      setPopAd,
      setAds,
      setUserInfo,
    };
  },
  { persist: true }
  // {
  //   persist: {
  //     key: 'store.key.app',
  //     storage: window.localStorage,
  //   },

  // }
);
