import RouterContainer from './RouterContainer.vue';
import ConfigApp from './ConfigApp.vue';
import Icon from './icon/Icon.vue';
/**
 * 通用组件在这里注册
 */
export function setupGlobCom(app) {
  app
    .component('ConfigApp', ConfigApp)
    .component('RouterContainer', RouterContainer)
    .component('Icon', Icon);
}
