import { Dialog } from 'vant';
import { Notify } from 'vant';
import { ConfigProvider } from 'vant';
/**
 * 通用组件在这里注册
 */
export function setupVant(app) {
  app.use(Notify);
  app.use(Dialog);
  app.use(ConfigProvider);
}
