import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { useAppStore, useDictStore } from '@/stores';

const store = createPinia();
store.use(
  createPersistedState({
    storage: sessionStorage,
  })
);

export const setupStore = (app) => {
  app.use(store);
  const { initDict } = useDictStore();
  initDict();
};
export { store };
