<script setup>
import { useAppStore } from '@/stores';

const { setCssVarTheme } = useAppStore();

const props = defineProps({
  size: { type: String, default: 'default' },
});
// 初始化所有主题色
onMounted(() => {
  setCssVarTheme();
});
</script>
<template>
  <van-config-provider class="h-full relative">
    <slot></slot>
  </van-config-provider>
</template>
