import { alertError, alertSuccess } from '@/components/common';
import { useCssVar } from '@vueuse/core';
import axios from 'axios';
import moment from 'moment';
const modules = import.meta.glob('../views/**/*.vue');

let index = moment().valueOf();

export const getCssVar = (prop, dom = document.documentElement) => {
  return useCssVar(prop, dom);
};

export const setCssVar = (prop, val, dom = document.documentElement) => {
  dom.style.setProperty(prop, val);
};
/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str) => {
  if (!str) return '';
  return str.replace(/\-(\w)/g, (_, letter) => {
    return letter.toUpperCase();
  });
};
/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str) => {
  return str
    .replace(/([A-Z])/g, '-$1')
    .replaceAll('__', '-')
    .replaceAll('_', '-')
    .toLowerCase();
};

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString();
  });
  return str;
}

/**
 * 首字母大写
 */
export function firstUpperCase(str) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * 文件下载
 * @param {Object} config
 * @param {Function} callback
 */
export async function downloadFile(config, callback) {
  let res = await axios({
    method: config.method || 'get',
    headers: config.headers,
    url: config.url,
    data: config.params || {},
    responseType: 'blob', //定义接口响应的格式,很重要
    onDownloadProgress: (evt) => {
      if (!evt.total) return null;
      callback?.(parseInt((evt.loaded / evt.total) * 100));
    },
  });
  blobDownFile(res.data, config.fileName);
}

/**
 * 下载文件
 * @param data
 * @param fileName
 */
export const blobDownFile = (data, fileName) => {
  if (data.type === 'application/json') {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const jsonData = JSON.parse(fileReader.result);
      if (jsonData.code) {
        alertError(jsonData.msg);
      }
    };
    fileReader.readAsText(data);
  } else {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    //IE10以上支持blob但是依然不支持download
    if ('download' in document.createElement('a')) {
      //支持a标签download的浏览器
      const link = document.createElement('a'); //创建a标签
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } else {
      //其他浏览器
      navigator.msSaveBlob(blob, fileName);
    }
  }
};

/**
 * 将数组转换成map
 * @param {Array[{label:string, value:any,...}]} dict
 * @returns
 */
export const dumpMap = (dict) => {
  let m = {};
  dict.forEach((item) => {
    m[item.value] = item;
  });
  return m;
};

/**
 * 从数组中获取指定值的名称
 * @param {*} dict
 * @param {*} value
 * @returns
 */
export const getLabelByValueOfArray = (dict, value) => {
  const item = dict.find((item) => item.value === value);
  return item?.label || '';
};
export const getItemByValueOfArray = (dict, value) =>
  dict.find((item) => item.value === value);

/**
 * 字符串转Blob
 * @param {String} str 请求数据
 * @returns {Object}
 **/
export function string2Blob(str) {
  return new Blob([str], {
    type: 'application/octet-stream',
  });
}

export const isServer = typeof window === 'undefined';
/**
 * 是否是浏览器
 */
export const isClient = !isServer;

/**
 * 数组B是否包含数组A的所有内容
 * @param {*} arrayA
 * @param {*} arrayB
 * @returns
 */
export const arrayIsContain = (arrayA, arrayB) =>
  arrayA.every((item) => arrayB.includes(item));

/**
 * 复制到剪切板
 * @param {String} text
 * @returns
 */
export const copyTextToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    return true;
  }
  const input = document.createElement('input');
  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 9999);
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  document.body.removeChild(input);
  return true;
};

export const allModules = () => modules;

export const deadlineText = (seconds) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const secondsLeft = seconds % 60;

  let ts = [];
  if (days > 0) ts.push(`${days}天`);
  if (hours > 0) ts.push(`${hours}小时`);
  if (minutes > 0) ts.push(`${minutes}分钟`);
  if (secondsLeft > 0) ts.push(`${secondsLeft}秒`);
  return ts.join('');
};

/**
 * 获取gps信息
 * @returns
 */
export const getLocation = () => {
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geolocation', function () {
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        useNative: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        // buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        // zoomToAccuracy: true,
        //  定位按钮的排放位置,  RB表示右下
        // buttonPosition: 'RB',
      });

      AMap.event.addListener(geolocation, 'complete', onComplete);
      AMap.event.addListener(geolocation, 'error', onError);
      geolocation.getCurrentPosition();
      function onComplete(data) {
        // data是具体的定位信息
        console.log('data>>>>>>', data);
        resolve({ latitude: data.position.lat, longitude: data.position.lng });
        // alertSuccess(data);
      }
      function onError(data) {
        // 定位出错
        // alertError(data);
        console.log('error:', data);
        resolve(null);
      }
    });
    // if (
    //   navigator.geolocation &&
    //   typeof navigator.geolocation.getCurrentPosition === 'function'
    // ) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       if (position.coords) {
    //         resolve(position.coords);
    //       } else {
    //         console.log('获取手机位置失败，请检查gps权限是否拒绝了');
    //         resolve(null);
    //       }
    //     },
    //     (error) => {
    //       console.log('获取手机位置失败，请检查gps权限是否拒绝了');
    //       resolve(null);
    //     }
    //   );
    // } else {
    //   resolve(null); // 浏览器不支持定位
    // }
  });
};

// 计算距离
export const distance = (la1, lo1, la2, lo2) => {
  var La1 = (la1 * Math.PI) / 180.0;
  var La2 = (la2 * Math.PI) / 180.0;
  var La3 = La1 - La2;
  var Lb3 = (lo1 * Math.PI) / 180.0 - (lo2 * Math.PI) / 180.0;
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(La3 / 2), 2) +
          Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)
      )
    );
  s = s * 6378.137; //地球半径
  s = Math.round(s);
  return s;
};

export const distanceStr = (la1, lo1, la2, lo2) => {
  const dis = distance(la1, lo1, la2, lo2);
  return `< ${dis.toFixed(2)}km`;
};

export const isQueryEqual = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (let key of obj1Keys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const formatPrice = (fen) => {
  fen = fen || 0;
  if (fen) {
    if (fen < 0) {
      fen = fen * -1;
    }
    return parseFloat(parseFloat(fen / 100.0)).toFixed(2);
  }
  return '0.00';
};

export const formatPriceYuan = (fen) => {
  fen = fen || 0;
  if (fen) {
    if (fen < 0) {
      fen = fen * -1;
    }
    return Number(fen / 100).toFixed(0);
  }
  return '0.00';
};

export const formatTime = (t, def) => {
  if (t) {
    return moment(t).format('YYYY-MM-DD HH:mm:ss');
  }
  return def || '';
};
export const formatDate = (t, def) => {
  if (t) {
    return moment(t).format('YYYY-MM-DD');
  }
  return def || '';
};

export const expiredDays = (t) => {
  return moment(t).diff(moment(), 'days');
};

export const formatOrderType = (v) => {
  var str = '';
  switch (v) {
    case 1:
      str = '整租';
      break;
    case 2:
      str = '散场';
      break;
    case 3:
      str = '充值卡';
      break;
    case 4:
      str = '训练';
      break;
    case 5:
      str = '会员卡';
      break;
    case 6:
      str = '押金';
      break;
    case 7:
      str = '预付会员卡';
      break;
    case 8:
      str = '长包';
      break;
    case 9:
      str = '商品';
      break;
    case 10:
      str = '赛事';
      break;
    case 11:
      str = '约球';
      break;
    case 12:
      str = '礼品卡';
      break;
    case 13:
      str = '活动';
      break;
    case 14:
      str = '包月保险';
      break;
    case 16:
      str = '次票';
      break;
    case 17:
      str = '补票';
      break;
    case 18:
      str = '充值';
      break;
    case 19:
      str = '单次保险';
      break;
    case 21:
      str = '前台零售';
      break;
    case 22:
      str = '售货机';
      break;
    case 24:
      str = '充值卡';
      break;
    case 25:
      str = '计时卡';
      break;
    case 26:
      str = '次卡';
      break;
    case 27:
      str = 'VIP卡';
      break;
    case 28:
      str = '约球';
      break;
    case 29:
      str = '线上-零售';
      break;
    case 99:
      str = '锁场';
      break;
  }
  return str;
};

// 折扣小数点
export const formatToZhe = (v) => {
  if (v == 0 || v == 1) {
    return 0;
  }
  let num = (v * 10).toFixed(1);
  return num;
};

// 运动类型
export const formatArenaType = (v) => {
  var str = '';
  switch (v) {
    case 1:
      str = '篮球';
      break;
    case 2:
      str = '足球';
      break;
    case 3:
      str = '羽毛球';
      break;
    case 4:
      str = '网球';
      break;
    case 5:
      str = '游泳馆';
      break;
    case 6:
      str = '乒乓球';
      break;
    case 7:
      str = '排球';
      break;
    case 8:
      str = '蹦床';
      break;
    case 9:
      str = '射箭';
      break;
    case 10:
      str = '多功能';
      break;
    case 11:
      str = '拓展';
      break;
    case 12:
      str = '滑雪';
      break;
    case 13:
      str = '健身房';
      break;
    case 14:
      str = '高尔夫';
      break;
    case 15:
      str = '滑板';
      break;
    case 16:
      str = '攀岩';
      break;
    case 17:
      str = '飞盘';
      break;
    case 18:
      str = '壁球';
      break;
    case 19:
      str = '桌球';
      break;
    case 20:
      str = '体能';
      break;
    case 21:
      str = '匹克球';
      break;
    case 22:
      str = '冰壶';
      break;
    case 23:
      str = '滑冰';
      break;
  }
  return str;
};

// 获取卡券类型
export const getTickOrCardCode = (type) => {
  let label = '';
  switch (type) {
    case 16:
      label = 'individualTickets';
      break;
    case 26:
      label = 'countCards';
      break;
    case 25:
      label = 'depositCards';
      break;
    case 1:
      label = 'courts';
      break;
    case 8:
      label = 'longTerm';
      break;
    case 5:
      label = 'vips';
      break;
    case 28:
      label = 'appointment';
      break;
  }
  return label;
};

export const genUuid = () => {
  return `${moment().valueOf()}${Math.random()}`.replace('.', '');
};
