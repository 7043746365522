import { VALUE_TYPE_NUMBER } from '@/consts/dict';
import { sleep, toAnyString } from '@/utils';
import moment from 'moment';

export default {
  login: () => ({
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI1NzEwODFjNjY2MDc1OWQ2NGZhZjc2ODUiLCJ1c2VybmFtZSI6InltRGF0YUFkbWluIiwicm9sZSI6MSwic2luZ2xlIjowLCJleHAiOjE3MjAyMjc5MTg5NjQsImlzR292IjowLCJpYXQiOjE3MTk5Njg3MTh9.KytwZCBNctxxGh6o8PkFBKmlRHL-Ut5crM5lIVSiqxk',
    pwd: 2,
    user: {
      _id: '571081c6660759d64faf7685',
      id: '571081c6660759d64faf7685',
      username: 'admin',
      nickname: '管理员',
      role: 1,
      theme: 0,
    },
  }),

  ['/cards/list']: async (args) => {
    console.log('/cards/list>>>', args);
    const { status } = args;
    await sleep(200);
    let cards = [
      {
        id: 'status_1',
        logo: 'https://file-test.us-smart.cn/upload/file/20241103/140FA313E74E34DABCF99F4750BD8EA9.PNG',
        name: '刺猬工厂充值卡',
        desc: '刺猬攻厂通用充值卡，享受散客次票8折。',
        type: 1,
        cardNo: '1321062215241902412468',
        balance: 100,
        deadline: moment().format('YYYY-MM-DD'),
        subCards: 2, // 子卡数量
        balance: 201,
        status,
      },
      {
        id: 'status_0',
        logo: 'https://file-test.us-smart.cn/upload/file/20241103/140FA313E74E34DABCF99F4750BD8EA9.PNG',

        name: '刺猬工厂次卡',
        type: 2,
        count: 20,
        desc: '刺猬攻厂通用次卡，享受散客次票8折。',
        cardNo: '1321062215241902412468',
        deadline: moment().format('YYYY-MM-DD'),
        status,
      },
      {
        id: 'status_1',
        logo: 'https://file-test.us-smart.cn/upload/file/20241103/140FA313E74E34DABCF99F4750BD8EA9.PNG',
        cardNo: '1321062215241902412468',

        name: '刺猬工厂会员',
        type: 3,
        desc: '刺猬攻厂通用会员卡，每天可领一张次票。',
        deadline: moment().format('YYYY-MM-DD'),
        status,
      },
      {
        id: 'status_1',
        logo: 'https://file-test.us-smart.cn/upload/file/20241103/140FA313E74E34DABCF99F4750BD8EA9.PNG',

        name: '刺猬工厂vip',
        desc: '订单内容4',
        type: 4,
        cardNo: '1321062215241902412468',
        deadline: moment().format('YYYY-MM-DD'),
        status,
      },
    ];

    return { data: cards };
  },

  ['/order/getById']: async ({ id }) => {
    console.log('>>>>/order/getById:', id);
    let data = {
      id: moment().valueOf() + 1,
      venue: '刺猬工厂',
      desc: '订单内容',
      timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      type: '散客',
      payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      price: 140.33,
      payed: 123.2,
      reduce: 2, // 减免
      deadline: moment().add(10, 'seconds').valueOf(),
      refundAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      seqNo: '1321080214045901657954',
      referralCode: '1321080214045901657954',
      payChannel: '微信',
      items: [
        {
          label: '全场（木板）',
          price: 140.33,
          timeAt: '07-30 周五 13：00-14：00',
        },
        {
          label: '羽毛球（木板）',
          price: 140.33,
          timeAt: '07-30 周五 13：00-14：00',
        },
      ],
      remark:
        '有效期为一个月(购买当天开始)，柒号主场所有自营馆散客票通用；有效期内每天可免费自动兑换一张散客门票。',
    };

    let status = id.split('_')[1];
    data.status = parseInt(status);

    return data;
  },

  ['/orders/list']: async (args) => {
    console.log('/orders/list>>>', args);
    const { status } = args;
    await sleep(200);
    let orders = [
      {
        id: 'status_1',
        venue: '刺猬工厂',
        desc: '订单内容',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 2, // 减免
        status: 1,
      },
      {
        id: 'status_2',
        venue: '刺猬工厂',
        desc: '订单内容222',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客33213',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 2, // 减免
        deadline: moment().add(4, 'seconds').valueOf(),
        status: 2,
      },
      {
        id: 'status_3',
        venue: '刺猬工厂',
        desc: '订单内容322233',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 2, // 减免
        status: 3,
      },
      {
        id: 'status_4',
        venue: '刺猬工厂',
        desc: '订单内容4',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 3, // 减免
        status: 4,
      },
      {
        id: 'status1_4',
        venue: '刺猬工厂',
        desc: '订单内容5',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 2, // 减免
        status: 4,
      },
      {
        id: 'status3_3',
        venue: '刺猬工厂',
        desc: '订单内容6',
        timeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '散客',
        payedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: 140.33,
        payed: 123.2,
        reduce: 2, // 减免
        status: 3,
      },
    ];
    if (status) {
      return { data: orders.filter((item) => item.status === status) };
    }

    return { data: orders };
  },
  ['/ticket/getByType']: async () => {
    await sleep(200);
    return {
      data: [
        {
          id: moment().valueOf() + 1,
          name: '散客单次票11',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
          deadline: moment().format('YYYY-MM-DD'),
          used: 1,
        },
        {
          id: moment().valueOf() + 2,
          name: '散客单次票22',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
          deadline: moment().format('YYYY-MM-DD'),
          used: 0,
        },
        {
          id: moment().valueOf() + 3,
          name: '散客单次票33',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
          deadline: moment().format('YYYY-MM-DD'),
          used: 1,
        },
        {
          id: moment().valueOf() + 4,
          name: '散客单次票44',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
          deadline: moment().format('YYYY-MM-DD'),
          used: 0,
        },
        {
          id: moment().valueOf() + 5,
          name: '散客单次票55',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
          deadline: moment().format('YYYY-MM-DD'),
          used: 1,
        },
      ],
    };
  },
  ['/venue/getTicketQrcode']: async ({ id }) => {
    await sleep(500);
    return {
      id,
      url: 'www.baidu.com?id=' + id,
    };
  },

  ['/venue/bookingCardsRangeByType']: async (params) => {
    console.log('/venue/bookingCardsRangeByType', params);
    await sleep(200);
    let id = 1;
    return [
      {
        name: '09:00-10:00',
        price: 12,
        enabled: 0,
        id: params.cardId + '_' + id++,
      },
      {
        name: '10:00-11:00',
        price: 13,
        enabled: 0,
        id: params.cardId + '_' + id++,
      },
      {
        name: '11:00-12:00',
        price: 14,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '12:00-13:00',
        price: 121,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '13:00-14:00',
        price: 122,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '14:00-15:00',
        price: 123,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '15:00-16:00',
        price: 124,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '16:00-17:00',
        price: 125,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '09:00-10:00',
        price: 12,
        enabled: 0,
        id: params.cardId + '_' + id++,
      },
      {
        name: '10:00-11:00',
        price: 13,
        enabled: 0,
        id: params.cardId + '_' + id++,
      },
      {
        name: '11:00-12:00',
        price: 14,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '12:00-13:00',
        price: 121,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '13:00-14:00',
        price: 122,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '14:00-15:00',
        price: 123,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '15:00-16:00',
        price: 124,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '16:00-17:00',
        price: 125,
        id: params.cardId + '_' + id++,
        enabled: 1,
      },
      {
        name: '09:00-10:00',
        price: 12,
        id: params.cardId + '_' + id++,
        enabled: 0,
      },
      {
        name: '10:00-11:00',
        price: 13,
        id: params.cardId + '_' + id++,
        enabled: 0,
      },
      {
        name: '11:00-12:00',
        price: 14,
        id: params.cardId + '_' + id++,
        enabled: 1,
      },
      {
        name: '12:00-13:00',
        price: 121,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '13:00-14:00',
        price: 122,
        id: params.cardId + '_' + id++,
        enabled: 1,
      },
      {
        name: '14:00-15:00',
        price: 123,
        id: params.cardId + '_' + id++,
        enabled: 1,
      },
      {
        name: '15:00-16:00',
        price: 124,
        enabled: 1,
        id: params.cardId + '_' + id++,
      },
      {
        name: '16:00-17:00',
        price: 125,
        id: params.cardId + '_' + id++,
        enabled: 1,
      },
    ];
  },

  ['/venue/bookingCardsByType']: async (params) => {
    console.log('/venue/bookingCardsByType', params);
    await sleep(200);
    let id = moment().valueOf();
    return [
      {
        name: '全场',
        cards: [
          {
            id: params.id + '_' + id++,
            name: 'A号全场',
            count: 0,
          },
          {
            id: params.id + '_' + id++,
            name: 'B号全场',
            count: 0,
          },
        ],
      },
      {
        name: '1号半场',
        cards: [
          {
            id: params.id + '_' + id++,
            name: '1号-A号全场',
            count: 0,
          },
          {
            id: params.id + '_' + id++,
            name: '1号-B号全场',
            count: 0,
          },
        ],
      },
      {
        name: '2号半场',
        cards: [
          {
            id: params.id + '_' + id++,
            name: '2号-A号全场',
            count: 0,
          },
          {
            id: params.id + '_' + id++,
            name: '2号-B号全场',
            count: 0,
          },
        ],
      },
    ];
  },
  ['/venue/bookingTypes']: async ({ id }) => {
    await sleep(200);
    console.log('/venue/bookingTypes>>>>>', id);
    return [
      {
        name: '篮球',
        type: 'basketball',
        des: '整租低至30元',
      },
      {
        name: '羽毛球',
        type: 'badminton',
        des: '整租低至30元',
      },
      {
        name: '攀岩',
        type: 'rockClimbing',
        des: '整租低至30元',
      },
    ];
  },
  ['/venue/getById']: async ({ id }) => {
    await sleep(500);
    console.log('/venue/getById>>>>>', id);
    return {
      id,
      name: '新明半岛文化体育中心',
      subName: '工作日18-22点及周末含午休免费开放',
      address: '杭州市余杭区天目山路和五常大道交叉口西溪影响成一期楼顶',
      image: 'https://www.myarena7.com/assets/image/solution-big1.png',
      sports: [
        'ant-design:dribbble-outlined',
        'ant-design:dribbble-outlined',
        'ant-design:dribbble-outlined',
      ],
      distance: '1.2km',
      tags: ['24h', '免费', '室内'],
      openTimeFrom: '08:00',
      openTimeTo: '22:00',
      tickets: [
        {
          name: '篮球',
          type: 'basketball',
          des: '整租低至30元',
        },
        {
          name: '羽毛球',
          type: 'badminton',
          des: '整租低至30元',
        },
        {
          name: '攀岩',
          type: 'rockClimbing',
          des: '整租低至30元',
        },
      ],
      cards: [
        {
          name: '散客单次票',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
        },
        {
          name: '散客单次票',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
        },
        {
          name: '散客单次票',
          count: 1,
          des: '仅营业时间内使用',
          price: 69,
        },
      ],
      layoutImage: 'https://www.myarena7.com/assets/image/solution-big1.png',
      introduce:
        '柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，',
      infrastructure: '一片标准木板全场，二片标准地胶全场，四片',
      traffics: [
        {
          label: '自驾',
          text: '柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主',
        },
        {
          label: '地铁',
          text: '柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主',
        },
        {
          label: '打车',
          text: '柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主',
        },
        {
          label: '其他',
          text: '柒号主场位于浙江杭州滨江区白马湖路7号，柒号主场位于浙江杭州滨江区白马湖路7号，柒号主',
        },
      ],
    };
  },
  /**
   * 查询推荐的场馆
   * @param {Number} page
   * @param {Number} pageSize
   * @returns
   */
  ['/venue/list']: async ({ page, pageSize, ...query }) => {
    console.log(
      `查询推荐的场馆:getVenues(query:${JSON.stringify(query)}, page:${page},pageSize:${pageSize})`
    );
    pageSize = pageSize || 5;
    if (page < 2) {
      return {
        data: new Array(pageSize).fill(0).map((_, index) => ({
          id: '' + moment().valueOf() + index,
          name: '新明半岛文化体育中心',
          address: '杭州市余杭区天目山路和五常大道交叉口西溪影响成一期楼顶',
          image: 'https://www.myarena7.com/assets/image/solution-big1.png',
          sports: [
            'ant-design:dribbble-outlined',
            'ant-design:dribbble-outlined',
            'ant-design:dribbble-outlined',
          ],
          distance: '1.2km',
          tags: ['24h', '免费', '室内'],
        })),
        page,
        pageSize,
        total: 11,
      };
    }

    return {
      data: [
        {
          id: '' + moment().valueOf() + 10000000,
          name: '新明半岛文化体育中心',
          address: '杭州市余杭区天目山路和五常大道交叉口西溪影响成一期楼顶',
          image: 'https://www.myarena7.com/assets/image/solution-big1.png',
          sports: [
            'ant-design:dribbble-outlined',
            'ant-design:dribbble-outlined',
            'ant-design:dribbble-outlined',
          ],
          distance: '1.2km',
          tags: ['24h', '免费', '室内'],
        },
      ],
      page,
      pageSize,
      total: 11,
    };
  },
};
