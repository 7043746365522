import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from './router';
// 引入全局样式
import '@/styles/index.scss';
import 'animate.css';
// 导入全局的svg图标
import '@/plugins/svgIcon';
import '@/assets/yfont/iconfont.css';
// 引入windi css
import '@/plugins/unocss';
import { setupStore } from './stores';
import { setupGlobCom } from '@c';
import { setupDirective } from './directives/setupDirective';
import { setupVant } from '@/plugins/vant';

const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
  // 处理错误
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  console.log(err, info);
};
app.config.warnHandler = (msg, vm, trace) => {
  console.log(msg, trace);
};
setupDirective(app);
//pinia持久化
setupStore(app);
setupGlobCom(app);
setupRouter(app);
setupVant(app);

app.mount('#app');
