/**
 * 字典表对象
 *
 * Map<category, List<Object{
 *    code: string,
 *    label: string,
 *    value: string,
 *    color: string,
 *    children: List<...>
 * }>>
 */
import { defineStore } from 'pinia';
import api from '@api';
import { VALUE_TYPE_NUMBER } from '@/consts/dict';

function formatDict(dict) {
  const res = {};
  const dictMap = {};
  for (const category in dict) {
    const items = dict[category];
    const list = [];
    for (const item of items) {
      if (item.valueType === VALUE_TYPE_NUMBER) {
        item.value = Number(item.value);
      }
      dictMap[item.code] = item;
      dictMap[`${category}-${item.value}`] = item;
      list.push(item);
    }
    res[category] = list;
  }
  return { dict: res, dictMap };
}

export const useDictStore = defineStore(
  'dict.store',
  () => {
    const dictRes = reactive({
      dict: {},
      version: 0,
      dictMap: {},
    });
    // 设置字典
    const initDict = async () => {
      let res = await api.getDict({
        version: dictRes.version,
      });
      if (res && res.data) {
        let { dict, dictMap } = formatDict(res.data);
        dictRes.dict = dict;
        dictRes.version = res.version;
        dictRes.dictMap = dictMap;
      }
      console.log(dictRes.dictMap);
    };

    const getAllCategories = () => Object.keys(dictRes.dict);

    // 根据分类名称获取字典
    const getDict = (category) => {
      return dictRes.dict[category] || [];
    };

    const dumpToDictByValue = (category) => {
      let items = getDict(category);
      let dict = {};
      if (items) {
        for (const item of items) {
          dict[item.value] = item;
        }
      }
      return dict;
    };

    const getInfoByCode = (code) => {
      return dictRes.dictMap[code];
    };

    const getInfoByValue = (category, value) =>
      dictRes.dictMap[`${category}-${value}`];

    const getLabelByValue = (category, value) => {
      const info = getInfoByValue(category, value);
      return info ? info.label : value;
    };

    return {
      dictRes,
      getDict,
      initDict,
      getInfoByCode,
      getInfoByValue,
      getLabelByValue,
      dumpToDictByValue,
      getAllCategories,
    };
  },
  {
    persist: {
      key: 'store.key.dict',
      storage: window.localStorage,
    },
  }
);
