import router from '@/router';
import { defineStore } from 'pinia';
import api from '@api';
import { VIEW_NAME_LOGIN } from '@/consts';

/**
 * 用户认证相关
 * user: 用户信息
 * token: 用户token
 */
export const userAuthStore = defineStore(
  'auth.store',
  () => {
    const userInfo = ref({});
    const token = ref(null);
    const companyId = ref(null);
    const username = ref('');
    const rememberMe = ref(true);
    // 携程注册code
    const tripCode = ref();

    const setTripCode = async (code) => {
      tripCode.value = code.replaceAll(' ', '+');
      return tripLogin();
    };

    const tripLogin = async () => {
      try {
        clear();

        //   {
        //     "code": 200,
        //     "data": {
        //         "sessionToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3Mjg3MWVmOTY1YmMwMDc2NzUyZmU1NCIsIm5hbWUiOiIiLCJjaWQiOiIiLCJjY2EiOjAsImV4cCI6MTczOTQxODIzNH0.5R1DcT9T1ZxN8yAiMnbaNVCxU0hTti5E03wJhI1EbUU",
        //         "user": {
        //             "uid": "672871ef965bc0076752fe54",
        //             "idType": 11,
        //             "id": "ctrip-foo-stub",
        //             "openId": "",
        //             "weixinOpenId": "",
        //             "alipayUserId": "",
        //             "miniProgramOpenId": "",
        //             "nickname": "",
        //             "name": "",
        //             "avatar": "https://arena7.oss-cn-hangzhou.aliyuncs.com/icon/avatar4.png",
        //             "gender": 0,
        //             "phone": "138******01",
        //             "birthday": "",
        //             "idCard": "",
        //             "idCardType": 1,
        //             "faceUrl": "",
        //             "coins": 0,
        //             "height": 0,
        //             "weight": 0,
        //             "positions": [],
        //             "needRealPersonAuth": false,
        //             "realPersonAuthed": false
        //         }
        //     }
        // }
        const res = await api.tripLogin({
          data: tripCode.value,
        });
        const { newUser, user: u, sessionToken: t } = res;
        if (!newUser) {
          userInfo.value = u;
          token.value = t;
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('携程注册失败:', error);
      }
    };

    const setLoginInfo = ({ user: u, sessionToken: t }) => {
      userInfo.value = u;
      token.value = t;
    };

    function clear() {
      userInfo.value = null;
      token.value = null;
    }

    function isLogIn() {
      return !!token.value;
    }

    function setOrderUserInfo({ userRealName, idCard }) {
      userInfo.value.name = userRealName;
      userInfo.value.idCard = idCard;
    }

    return {
      userInfo,
      token,
      username,
      rememberMe,
      isLogIn,
      clear,
      setTripCode,
      tripCode,
      companyId,
      setLoginInfo,
      setOrderUserInfo,
    };
  },
  { persist: true }
);
