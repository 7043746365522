export const VIEW_NAME_LOGIN = 'login';
export const VIEW_NAME_HOME = 'home';
export const VIEW_NAME_404 = 'home404';

export const VIEW_NAME_HOME_MAIN = 'home-main';
export const VIEW_NAME_HOME_MAIN_PATH = '/home/main';

export const VIEW_NAME_VENUE_LIST = 'venue-list';
export const VIEW_NAME_VENUE_LIST_PATH = '/venue/list';

export const VIEW_NAME_MINE_ORDER = 'mine-order';
export const VIEW_NAME_MINE_ORDER_PATH = '/mine/orders';
