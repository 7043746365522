/**
 * 错误代码
 */
export const ERROR_CODE_OK = 200;
export const ERROR_CODE_BAD_REQUEST = 400;
export const ERROR_CODE_NOT_FOUND = 404;
export const ERROR_CODE_NO_AUTH = 401;

/**
 * 错误信息
 */
export const ERROR_TIME_OUT_MSG = '网络繁忙，请稍后重试';
export const ERROR_NOT_FOUND_MSG = '请求方法错误，请检查url或method';
