<script setup></script>
<template>
  <ConfigApp>
    <RouterContainer></RouterContainer>
  </ConfigApp>
</template>
<style lang="scss">
.size {
  width: 100%;
  height: 100%;
}
html,
body {
  position: relative;
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  background-color: var(--app-content-bg-color);
  @extend .size;
  #app {
    @extend .size;
  }
}
</style>
