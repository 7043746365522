import { sleep } from '@/utils';

export const METHODS_POST = 'post';
export const METHODS_GET = 'get';
export const TOKEN_NAME = 'sessionToken';
export const HEADER_TOKEN_NAME = 'x-access-token';
export const PRE_URL_APIS = '/v2';

import mockApi from './mockApis';
import productionApis from './productionApis';
import { VALUE_TYPE_NUMBER } from '@/consts/dict';

/**
 * 接口列表，具体查看./index.js中实现，具体支持多种格式:
 * 1. [apiName]: Function (params) => {}        将调用改function，并将结果返回
 * 2. [apiName]: Array    []                    将直接返回Array定义的数据
 * 3. [apiName]: Object   {d,data}              将直接返回 Object.data || Object.d 定义的数据
 * 4. [apiName]: Object   {path,method,noAuth}  将调用 api[method](args)方法并返回结果
 */
export let apiList = {
  getHomepagePublic: {
    path: '/v2/homepage/public',
    method: METHODS_GET,
  },
  getCompanySettings: {
    path: '/v2/company_settings',
    method: METHODS_GET,
  },
  tripLogin: {
    path: '/v2/partners/users/signup',
    method: METHODS_POST,
    noAuth: true,
  },
  sendSMS: {
    path: '/v2/sms',
    method: METHODS_POST,
    noAuth: true,
  },
  userLoginWithCode: {
    path: '/v2/partners/users/signin',
    method: METHODS_POST,
    noAuth: true,
  },

  registerWithCode: {
    path: '/v2/partners/users/binding',
    method: METHODS_POST,
    noAuth: true,
  },
  //我的订单列表
  getMyReceipts: {
    path: '/v2/receipts',
    method: METHODS_GET,
  },
  getDict: () => {
    return {
      data: {
        ['订单状态']: [
          { label: '未支付', value: 1, valueType: VALUE_TYPE_NUMBER },
          { label: '待支付', value: 2, valueType: VALUE_TYPE_NUMBER },
          { label: '交易成功', value: 3, valueType: VALUE_TYPE_NUMBER },
          { label: '退款中', value: 4, valueType: VALUE_TYPE_NUMBER },
          { label: '成功退款', value: 5, valueType: VALUE_TYPE_NUMBER },
          { label: '退款失败', value: 6, valueType: VALUE_TYPE_NUMBER },
          { label: '用户取消', value: 7, valueType: VALUE_TYPE_NUMBER },
        ],
        // ['卡包类型']: [
        //   { label: '充值卡', value: 1, valueType: VALUE_TYPE_NUMBER },
        //   { label: '次卡', value: 2, valueType: VALUE_TYPE_NUMBER },
        //   { label: '会员卡', value: 3, valueType: VALUE_TYPE_NUMBER },
        //   { label: 'VIP卡', value: 4, valueType: VALUE_TYPE_NUMBER },
        // ],
        ['场地状态']: [
          { label: '停用', value: 1, valueType: VALUE_TYPE_NUMBER },
          { label: '过期', value: 2, valueType: VALUE_TYPE_NUMBER },
          { label: '可用', value: 3, valueType: VALUE_TYPE_NUMBER },
          { label: '未支付', value: 4, valueType: VALUE_TYPE_NUMBER },
          { label: '支付中', value: 5, valueType: VALUE_TYPE_NUMBER },
          { label: '已预定', value: 6, valueType: VALUE_TYPE_NUMBER },
          { label: '已锁定', value: 7, valueType: VALUE_TYPE_NUMBER },
        ],
      },
    };
  },
};

/**
 * 加载mock资源
 */
if (import.meta.env.VITE_USE_MOCK) {
  apiList = { ...apiList, ...mockApi };
}

apiList = { ...apiList, ...productionApis };
