import axios from 'axios';
import { customBaseUrl } from './environment';
import {
  HEADER_TOKEN_NAME,
  METHODS_GET,
  METHODS_POST,
  TOKEN_NAME,
  apiList,
} from './apiList';
import { VIEW_NAME_LOGIN } from '@/consts';
import router from '@/router';
import { alertError } from '@/components/common';
import {
  ERROR_TIME_OUT_MSG,
  ERROR_CODE_BAD_REQUEST,
  ERROR_CODE_OK,
  ERROR_CODE_NO_AUTH,
  ERROR_NOT_FOUND_MSG,
} from '@/consts';
import { userAuthStore } from '@/stores';
import { API_SECRET, X_ACCESS_K, X_CID } from '@/consts/appConf';
import { md5 } from './md5';

let service = axios.create({
  baseURL: customBaseUrl,
  timeout: 90000,
});

/* 封装接口请求 */
const methods = Object.keys(apiList).reduce((acc, cur) => {
  if (typeof apiList[cur] === 'function') {
    return Object.assign(acc, {
      [cur]: async (args) => await apiList[cur](args),
    });
  }
  if (Array.isArray(apiList[cur])) {
    return Object.assign(acc, {
      [cur]: () => apiList[cur],
    });
  }
  let { path, method = METHODS_GET, noAuth, data, d } = apiList[cur];
  let url = path;
  if (data || d) {
    return Object.assign(acc, {
      [cur]: async () => data || d,
    });
  }
  // 确保返回的均为promise
  return Object.assign(acc, {
    [cur]: async (params) => {
      let data = '';
      if (method === METHODS_GET) {
        if (params) {
          url = appendParams(path, params);
          data = _formatParams(params);
        }
      } else {
        if (params) {
          data = JSON.stringify(params);
        }
      }
      let headers = getJsonRequestHeader(data);
      if (!noAuth) {
        headers[HEADER_TOKEN_NAME] = userAuthStore().token;
      }
      const key = method === METHODS_GET ? 'params' : 'data';
      let config = {
        method,
        headers,
        // [key]: params,
      };
      if (method != METHODS_GET) {
        config[key] = params;
      }
      let res = await service(url, config);
      // if (res.data.code == 200) {
      //   return res.data.data;
      // }
      return res;
    },
  });
}, {});
/**
 * 通用GET方法
 */
methods['get'] = async (url, params) => {
  /**
   * 如果url在@methods定义，直接返回，通常mock数据用
   */
  if (methods[url]) {
    return methods[url](params);
  }
  let data = '';
  if (params) {
    url = appendParams(url, params);
    data = _formatParams(params);
  }
  let headers = getJsonRequestHeader(data);
  // params = params || {};
  // if (params && typeof params === 'object') {
  //   params[TOKEN_NAME] = userAuthStore().token;
  // }
  let config = {
    method: METHODS_GET,
    headers,
    // params: params,
  };

  let res = await service(url, config);
  console.log('GET请求数据：', url, config, res);
  // if (res.data.code == 200) {
  //   return res.data.data;
  // }
  return res;
};
/**
 * 通用Post方法
 */
methods['post'] = async (url, params) => {
  /**
   * 如果url在@methods定义，直接返回，通常mock数据用
   */
  if (methods[url]) {
    return methods[url](params);
  }

  let data = '';
  if (params) {
    data = JSON.stringify(params);
  }

  let headers = getJsonRequestHeader(data);
  headers[HEADER_TOKEN_NAME] = userAuthStore().token;

  let config = {
    method: METHODS_POST,
    headers,
    data: params,
  };
  let res = await service(url, config);
  // // 列表接口返回总数: itemTotal
  // if (data.itemTotal) {
  //   return data;
  // }
  console.log('POST请求数据：', url, config, res);
  // if (res.data.code == 200) {
  //   return res.data.data;
  // }
  return res;
};

export const errorHandle = (statusCode, url, msg) => {
  let res = { success: false, msg, code: statusCode };
  switch (statusCode) {
    case ERROR_CODE_NO_AUTH:
      res.msg = res.msg || `401:登录过期，请重新登录`;
      sessionStorage.clear();
      alertError(res.msg || '网络异常，请稍后再试！');
      userAuthStore().clear();
      router.push({
        name: VIEW_NAME_LOGIN,
        replace: router.currentRoute.value.name === VIEW_NAME_LOGIN,
      });
      break;
    default:
      console.log(`http请求错误:`, statusCode, url, msg);
      alertError(res.msg || '网络异常，请稍后再试！');
  }
};

/* 响应拦截 */
service.interceptors.response.use(
  (res) => {
    console.log(`http请求返回:`, res);
    if (res.status !== ERROR_CODE_OK) {
      errorHandle(res.status, res.config.url, res.statusText);
      throw res;
    } else {
      console.log(`http status===200 请求返回:`, res.data, typeof res.data);
      if (res.data && res.data.data && res.data.code == 200) {
        return res.data.data;
      }
      if (res.data && res.data.msg && res.data.code !== 200) {
        errorHandle(res.data.code, res.config.url, res.data.msg);
        throw res.data;
      }
      return res.data;
    }
  },
  (error) => {
    console.log(`http请求错误:`, error);
    try {
      // 拦截接口超时
      const isTimeOut =
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') !== -1;
      if (isTimeOut) {
        alertError(ERROR_TIME_OUT_MSG);
      }
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          userAuthStore().clear();
          router.push({
            name: VIEW_NAME_LOGIN,
          });
        }

        alertError(
          error.message ||
            `请求[${error.config.method}]${error.config.url}不存在，请稍后再试`
        );
      }

      if (error.code === 'ERR_BAD_RESPONSE') {
        alertError(ERROR_TIME_OUT_MSG);
      }
    } catch (err) {
      console.log(err);
    }
    // 处理响应失败
    throw {
      code: ERROR_CODE_BAD_REQUEST,
      msg: ERROR_TIME_OUT_MSG,
    };
  }
);

function _getRequestHeader(data) {
  const headers = {};
  const tu = new Date().getTime() / 1000;
  const t = parseInt(String(tu), 10);

  const sign = _md5(data + _signSuffix(t));
  headers['x-access-k'] = X_ACCESS_K;
  headers['x-access-v'] = sign;
  headers['x-access-t'] = String(t);
  let token = userAuthStore().token;
  if (token) {
    headers['x-access-token'] = token;
  }
  headers['x-cid'] = X_CID;
  return headers;
}

function getJsonRequestHeader(data) {
  const headers = _getRequestHeader(data);
  headers['Content-Type'] = 'application/json';
  return headers;
}

function appendParams(uri, params) {
  if (uri === '') {
    return '';
  }
  const query = _formatParams(params);

  if (query !== '') {
    uri += -1 === uri.indexOf('?') ? '?' : '&';
    uri += query;
  }
  return uri;
}

function _formatParams(params) {
  const p = [];
  for (const k in params) {
    if (params[k] !== '' && params[k] !== undefined && params[k] !== null) {
      p.push(k + '=' + encodeURIComponent(params[k]).replace(/'/g, ''));
    }
  }
  return p.join('&');
}

function _getSecret() {
  let s = API_SECRET;
  return s;
}

function _code2str(code) {
  return String.fromCharCode(code);
}

function _signSuffix(t) {
  const secret = _getSecret();
  return (
    '&' +
    _code2str(107) +
    _code2str(101) +
    _code2str(121) +
    '=' +
    secret +
    '&' +
    _code2str(116) +
    '=' +
    t
  );
}

function _md5(str) {
  return String(md5(str));
}
export default methods;
