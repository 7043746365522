import { VIEW_NAME_404, VIEW_NAME_LOGIN } from './views';

export const APP_TITLE = import.meta.env.VITE_APP_TITLE;
/**
 * 不重定向白名单
 */
export const NO_REDIRECT_WHITE_LIST = [VIEW_NAME_LOGIN, VIEW_NAME_404];

/**
 * 不重置路由白名单
 */
export const NO_RESET_WHITE_LIST = [
  'Redirect',
  VIEW_NAME_LOGIN,
  VIEW_NAME_404,
  'Root',
];

export const PAGE_SIZE = 20;
