import {
  NO_REDIRECT_WHITE_LIST,
  NO_RESET_WHITE_LIST,
  VIEW_NAME_404,
  VIEW_NAME_HOME,
  VIEW_NAME_HOME_MAIN,
  VIEW_NAME_HOME_MAIN_PATH,
  VIEW_NAME_LOGIN,
  VIEW_NAME_MINE_ORDER,
  VIEW_NAME_MINE_ORDER_PATH,
  VIEW_NAME_VENUE_LIST,
  VIEW_NAME_VENUE_LIST_PATH,
} from '@/consts';
import { createRouter, createWebHistory } from 'vue-router';
import { userAuthStore } from '@/stores';
import { useTitle } from '@/hooks/useTitle';

export const route404 = {
  path: '/404',
  name: VIEW_NAME_404,
  meta: {
    noAuth: true,
    title: '页面未找到',
    noTagsView: true,
    keepAlive: true,
  },
  component: () => import('@v/error/404.vue'),
};

/**
 * 路由定义规则
 * name:'router-name'          设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
 *    title: 'title'            设置该路由在侧边栏和面包屑中展示的名字
 *    icon: 'svg-name'          设置该路由的图标
 *    noAuth: Boolean           是否不验证token(默认 false)
 *    noTagsView: true          如果设置为true，则不会出现在tag中(默认 false)
 *    breadcrumb: false         如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
 * }
 */
const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(),
  //使用前端路由，当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置，就像重新加载页面那样。
  scrollBehavior: () => ({ left: 0, top: 0 }),
  //是否禁止尾部斜线
  strict: true,
  routes: [
    {
      path: '/',
      redirect: VIEW_NAME_HOME_MAIN_PATH,
    },
    {
      path: '/login',
      name: VIEW_NAME_LOGIN,
      meta: {
        title: '登录',
        noAuth: true,
      },
      component: () => import('@v/login/Login.vue'),
    },
    {
      path: '/registerByCode',
      name: 'registerByCode',
      meta: {
        title: '注册',
        noAuth: true,
      },
      component: () => import('@v/login/registerByCode.vue'),
      props: true,
    },
    {
      path: VIEW_NAME_VENUE_LIST_PATH,
      name: VIEW_NAME_VENUE_LIST,
      meta: {
        title: '场馆',
        noAuth: true,
      },
      component: () => import('@v/commons/venues/VenueList.vue'),
    },

    {
      path: '/venue/detail/:id',
      name: 'venue-detail',
      meta: {
        title: '场馆',
        noAuth: true,
      },
      component: () => import('@v/commons/venue/VenueDetail.vue'),
    },
    {
      path: '/payRet/:result/:type/:id',
      name: 'pay-ticket',
      meta: {
        title: '支付成功',
        noAuth: true,
      },
      component: () => import('@v/commons/ok/PayRet.vue'),
      props: true,
    },
    {
      path: '/mine/orders/:state',
      name: VIEW_NAME_MINE_ORDER,
      meta: {
        title: '全部订单',
      },
      component: () => import('@/views/commons/orders/Orders.vue'),
      props: true,
    },
    {
      path: '/card/detail/:id/:cardType/:valid',
      name: 'CardDetail',
      meta: {
        title: '主卡详情',
      },
      component: () => import('@v/commons/cards/CardDetail.vue'),
      props: true,
    },
    {
      path: '/subCard/detail/:id/:cardType/:valid/:isSubCard',
      name: 'SubCardDetail',
      meta: {
        title: '副卡详情',
      },
      component: () => import('@v/commons/cards/SubCardDetail.vue'),
      props: true,
    },
    {
      path: '/card/useDetail/:id/:cardType',
      name: 'CardUseDetail',
      meta: {
        title: '使用明细',
      },
      component: () => import('@v/commons/cards/CardUseDetail.vue'),
      props: true,
    },
    {
      path: '/order/detail/:id',
      name: 'OrderDetail',
      meta: {
        title: '订单详情',
      },
      component: () => import('@v/commons/orders/OrderDetail.vue'),
      props: true,
    },
    {
      path: '/order/shareReceipt/:id',
      name: 'OrderShareReceipt',
      meta: {
        title: '邀请卡',
      },
      component: () => import('@v/commons/orders/OrderShareDetail.vue'),
      props: true,
    },
    {
      path: '/order/confirm/:arenaId/:seqNo',
      name: 'OrderConfirm',
      meta: {
        title: '订单确认',
      },
      component: () => import('@v/commons/orders/ConfirmOrder.vue'),
      props: true,
    },
    {
      path: '/mine/cards',
      name: 'MineCards',
      meta: {
        title: '我的卡包',
      },
      component: () => import('@v/commons/cards/Cards.vue'),
    },
    {
      path: '/rental/full/:venueId/:bookingType',
      name: 'FullRental',
      meta: {
        title: '整租',
      },
      component: () => import('@v/commons/rental/FullRental.vue'),
      props: true,
    },

    {
      path: '/tickets/:venueId',
      name: 'TicketsBuy',
      meta: {
        title: '卡券票',
      },
      component: () => import('@v/commons/ticket/Tickets.vue'),
      props: true,
    },
    {
      path: '/tickets/confirmTicket/:ticketType/:venueId/:id',
      name: 'ConfirmTicket',
      meta: {
        title: '次票确认',
      },
      component: () => import('@v/commons/ticket/ConfirmTicket.vue'),
      props: true,
    },

    {
      path: '/home',
      name: VIEW_NAME_HOME,
      meta: {
        noAuth: true,
      },
      component: () => import('@c/layout/Layout.vue'),
      children: [
        // 默认注册一个redirct组件, 在菜单加载完会覆盖该子路由
        {
          path: VIEW_NAME_HOME_MAIN_PATH,
          name: VIEW_NAME_HOME_MAIN,
          meta: {},
          component: () => import('@v/home/Main.vue'),
          children: [
            // 默认注册一个redirct组件, 在菜单加载完会覆盖该子路由
          ],
        },
        {
          path: '/home/comeIn',
          name: 'homeComeIn',
          meta: {
            noAuth: false,
          },
          component: () => import('@/views/home/comeIn/ComeIn.vue'),
          children: [
            // 默认注册一个redirct组件, 在菜单加载完会覆盖该子路由
          ],
        },
        {
          path: '/mine/main',
          name: 'mineMain',
          meta: {},
          component: () => import('@/views/home/mine/Main.vue'),
          children: [
            // 默认注册一个redirct组件, 在菜单加载完会覆盖该子路由
          ],
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = userAuthStore();
  console.log(
    `router.beforeEach: ${from.path} -> path:${to.path} name:${to.name} noAuth:${to.meta.noAuth} isLogin:${authStore.isLogIn()}`
  );

  // 携程注册 register/trip
  if (to.path === '/register/trip') {
    let { code } = to.query;
    let hasUser = await authStore.setTripCode(code);
    if (hasUser) {
      next({
        name: VIEW_NAME_HOME,
      });
    } else {
      next({
        path: `/registerByCode`,
        state: { data: code },
      });
      return;
    }

    return;
  }

  // router.beforeEach: / -> path:/login name:login noAuth:true isLogin:false
  if (!to.meta.noAuth && !authStore.isLogIn()) {
    if (from.path === '/login') {
      next({
        name: VIEW_NAME_HOME,
      });
    } else {
      next({
        name: VIEW_NAME_LOGIN,
        state: { redirect: to.fullPath },
      });
    }

    return;
  }
  if (NO_REDIRECT_WHITE_LIST.includes(to.name)) {
    next();
    return;
  }
  if (to.name === VIEW_NAME_LOGIN && authStore.isLogIn()) {
    // 已登录且要跳转的页面是登录页
    next({
      name: VIEW_NAME_HOME,
    });
    return;
  }
  next();
  return;
});

//全局解析守卫
// router.beforeResolve(async () => {});

router.afterEach((to) => {
  useTitle(to?.meta?.title);
});

export const resetRouter = () => {
  router.getRoutes().forEach((router) => {
    const { name } = router;
    if (name && !NO_RESET_WHITE_LIST.includes(name)) {
      router.removeRoute(name);
    }
    console.log('removeRoute:', name);
  });
};

export const setupRouter = (app) => {
  app.use(router);
};

export default router;
