import { useAppStore } from '@/stores';
import { isString } from '@/utils';

export const useTitle = (newTitle) => {
  const title = ref(
    newTitle ? `${useAppStore().useApp.title}-${newTitle}` : useAppStore.title
  );

  watch(
    title,
    (n, o) => {
      if (isString(n) && n !== o && document) {
        document.title = n;
      }
    },
    { immediate: true }
  );

  return title;
};
