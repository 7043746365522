<script setup>
import { Icon } from '@iconify/vue';
import { getCssVar } from '@/utils';
defineOptions({ name: 'ComIcon' });
const props = defineProps({
  icon: String,
  color: String,
  size: {
    type: [String, Number],
    default: 16,
  },
  hoverColor: {
    type: String,
    default: unref(getCssVar('--el-color-primary')),
  },
});

const isLocal = computed(() => !!props.icon?.startsWith('iconfont:'));

const symbolId = computed(() => {
  return unref(isLocal) ? `${props.icon.split('iconfont:')[1]}` : props.icon;
});

const localClz = computed(() => {
  return unref(isLocal) ? props.icon.split(':').join(' ') : '';
});

// 是否使用在线图标
const isUseOnline = computed(() => {
  return import.meta.env.VITE_USE_ONLINE_ICON === 'true';
});

const getIconifyStyle = computed(() => {
  const { color, size } = props;
  return { color, fontSize: `${size}px` };
});
</script>
<template>
  <span v-if="isLocal" :class="localClz" :style="getIconifyStyle"> </span>
  <template v-else>
    <Icon :icon="icon" :style="getIconifyStyle" />
  </template>
</template>
<style lang="scss">
.iconify {
  :deep(svg) {
    &:hover {
      // stylelint-disable-next-line
      color: v-bind(hoverColor) !important;
    }
  }
}

.iconify {
  &:hover {
    // stylelint-disable-next-line
    color: v-bind(hoverColor) !important;
  }
}
</style>
