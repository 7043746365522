export const VALUE_TYPE_NUMBER = 'number';
export const VALUE_TYPE_STRING = 'string';

export const valueTypeDict = [
  { label: '数字', value: VALUE_TYPE_NUMBER, type: 'warning' },
  { label: '字符串', value: VALUE_TYPE_STRING, type: 'success' },
];

export const orderStatusColor = (status) => {
  switch (parseInt(status)) {
    case 2:
      return 'var(--app-highlight-color)';
    case 3:
      return 'var(--app-colors-green)';
    case 4:
    case 5:
    case 6:
      return 'var(--app-primary-color)';
    case 1:
      return 'var(--app-colors-red)';
    default:
      return 'var(--app-text-color)';
  }
};
