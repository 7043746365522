import { useEventListener } from '@vueuse/core';
import { copyTextToClipboard } from '@/utils';
import { alertSuccess, alertError } from '@c/common';

/**
 * 文本复制指令（默认双击复制）
 */
export const copy = {
  mounted(el, binding) {
    const { value } = binding;
    if (value) {
      el.copyValue = value;
      const arg = binding.arg ?? 'dblclick';
      // Register using addEventListener on mounted, and removeEventListener automatically on unmounted
      useEventListener(el, arg, () => {
        const success = copyTextToClipboard(el.copyValue);
        success ? alertSuccess('复制成功') : alertError('复制失败');
      });
    } else {
      throw new Error(
        '[自定义指令: copy]: 需要指定复制内容! 例如 v-copy="modelValue"'
      );
    }
  },
  updated(el, binding) {
    el.copyValue = binding.value;
  },
};
