export const alertSuccess = (message) => {
  showToast(message);
};
export const alertError = (message) => {
  showToast(message);
};
export const alertWarning = (message) => {
  showToast(message);
};
export const alertInfo = (message) => {
  showToast(message);
};

export const alertConfirm = (
  message,
  opt = {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    messageAlign: 'center',
    title: '待确认',
    theme: 'default',
  }
) => {
  return showConfirmDialog({
    title: opt.title,
    message,
    theme: opt.theme,
    messageAlign: opt.messageAlign,
    confirmButtonText: opt.confirmButtonText,
    cancelButtonText: opt.cancelButtonText,
  });
};
